import { AxiosError } from "axios";

// need to figure out how to properly handle errors

export const handleAxiosError = (error: AxiosError) => {
  console.log(error.status);
  console.error(error.response);
  return error;
};

export const handleGenericError = (error: unknown) => {
  console.error("Failed to get article:", error);
  return error as Error;
};
