import { Route, Routes } from "react-router-dom";
import { lazy} from "react";
import SuspenseWrapper from "./SuspenseWrapper";

export const PrivateRoutes = () => {
    const Admin = lazy(() => import("../views/Admin"));
    const Home = lazy(() => import("../views/Home"));
    const About = lazy(() => import("../views/About"));
    const ArticleListPage = lazy(() => import("../views/ArticleListPage"));
    const ArticlePage = lazy(() => import("../views/ArticlePage"));
    const NotFoundPage = lazy(() => import("../views/NotFoundPage"));
    const LoginPage = lazy(() => import("../views/LoginPage"));
    const CreateAccountPage = lazy(() => import("../views/CreateAccountPage"));
    const ProfilePage = lazy(() => import("../views/ProfilePage"));
    const CreateArticlePage = lazy(() => import("../views/CreateArticlePage"));
    const ArticleUpdate = lazy(() => import("../views/ArticleUpdate"));

  return (
    <>
      <Routes>
        <Route path="/" element={<SuspenseWrapper/>}>
          <Route path="/" element={<Home />} />
          <Route path="/home" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/articles" element={<ArticleListPage />} />
          <Route path="/articles/:articleId" element={<ArticlePage />} />
          <Route path="*" element={<NotFoundPage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/articles/:articleId/update" element={<ArticleUpdate />} />
          <Route path="/create-account" element={<CreateAccountPage />} />
          <Route path="/profile" element={<ProfilePage />} />
          <Route path="/create-article" element={<CreateArticlePage />} />
          <Route path="/admin" element={<Admin />} />
        </Route>
      </Routes>
    </>
  );
};
