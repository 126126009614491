import { Link } from "react-router-dom";
import useUser from "../hooks/useUser";
import useAdmin from "../hooks/useAdmin";
import { useContext, useState } from "react";
import { AuthButton } from "./buttons/AuthButton";
import { AdminButton } from "./buttons/AdminButton";
import { ArticleContext } from "./context/ArticleContext";

const Navbar: React.FC = () => {
  const { clearSearchResults } = useContext(ArticleContext);
  const { user } = useUser();
  const isAdmin = useAdmin();

  const [isOpen, setIsOpen] = useState(false);

  const responsiveBtn =
    "text-xl leading-none normal-case btn btn-ghost rounded-none btn-xs sm:btn-lg md:btn-lg lg:btn-lg text-center focus:text-primary focus-within:text-primary active:text-primary";

  const handleLinkClick = () => {
    clearSearchResults();
    setIsOpen(false);
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <div className="m-auto mb-3 max-w-[640px] navbar bg-base-100 md:justify-center md:p-0">
        <div className="dropdown md:hidden">
          <label tabIndex={0} className="btn btn-ghost btn-circle" onClick={toggleDropdown}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="w-5 h-5"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 6h16M4 12h16M4 18h7"
              />
            </svg>
          </label>
          {isOpen && (
          <ul
            tabIndex={1}
            className="menu menu-sm dropdown-content z-[1] p-2 shadow bg-base-100 rounded-box w-52"
          >
            <li>
              <Link to="/about" onClick={handleLinkClick}>
                About
              </Link>
            </li>
            <li>
              <Link to="/articles" onClick={handleLinkClick}>
                Articles
              </Link>
            </li>
            <li>
              <Link to="/profile" onClick={handleLinkClick}>
                Profile
              </Link>
            </li>
            {isAdmin && <li>{AdminButton("adminBtn", isAdmin)}</li>}
          </ul>
          )}
        </div>
        <div className="inline-flex justify-between mb-0.5 w-[100%] align-middle md:hidden">
          <Link className={responsiveBtn} to="/" onClick={handleLinkClick}>
            Home
          </Link>
          {AuthButton(user, responsiveBtn)}
        </div>
        <div className="hidden md:flex">
          <div className="justify-center flex-1 p-0 navbar bg-base-100">
            <Link
              to="/home"
              className={responsiveBtn}
              onClick={handleLinkClick}
            >
              Home
            </Link>
            <Link
              to="/about"
              className={responsiveBtn}
              onClick={handleLinkClick}
            >
              About
            </Link>
            <Link
              to="/articles"
              className={responsiveBtn}
              onClick={handleLinkClick}
            >
              Articles
            </Link>
            <Link
              to="/profile"
              className={responsiveBtn}
              onClick={handleLinkClick}
            >
              Profile
            </Link>
            {AdminButton(responsiveBtn, isAdmin)}
            {AuthButton(user, responsiveBtn)}
          </div>
        </div>
      </div>
    </>
  );
};

export default Navbar;
