import axios, { AxiosError, AxiosResponse } from "axios";
import { Article as ArticleInfo } from "../models/Article.model";
import { handleAxiosError, handleGenericError } from "./errorHandling";

export const loadArticleInfo = async (
  articleId: string
): Promise<ArticleInfo | AxiosError | Error> => {
  try {
    const response = await axios.get<ArticleInfo>(process.env.REACT_APP_API_URL + `/api/articles/${articleId}`);
    return response.data;
  } catch (error: unknown) {
    if (axios.isAxiosError(error)) {
      return handleAxiosError(error);
    } else {
      return handleGenericError(error);
    }
  }
};

export const createArticle = async (
  title: string,
  content: string[],
  tags: string[],
  displayName: string | null
): Promise<ArticleInfo | AxiosError | Error> => {
  try {
    const response = await axios.post<ArticleInfo>(
      process.env.REACT_APP_API_URL + "/api/articles",
      {
        title,
        content,
        tags,
        creator: {
          name: displayName,
        },
      }
    );
    return response.data;
  } catch (error: unknown) {
    if (axios.isAxiosError(error)) {
      return handleAxiosError(error);
    } else {
      return handleGenericError(error);
    }
  }
};

interface Pagination {
  count: number;
  pageCount: number;
  currentPage: number;
}

interface ArticlesData {
  articles: ArticleInfo[];
  pagination: Pagination;
}

export const loadArticles = async (
  page?: number,
  limit?: number,
  query?: string,
  tag?: string[]
): Promise<ArticlesData | AxiosError | Error> => {
  let url = process.env.REACT_APP_API_URL + `/api/articles`;
  try {
    if (query) {
      url += `?search=${query}`;
    }

    if (page) {
      url += `${query ? "&" : "?"}page=${page}`;
    }

    if (limit) {
      url += `&limit=${limit}`;
    }

    if (tag) {
      url += `&tag=${tag}`;
    }

    const response = await axios.get<ArticlesData>(url);
    return response.data;
  } catch (error: unknown) {
    if (axios.isAxiosError(error)) {
      console.log(error);
      return handleAxiosError(error);
    } else {
      return handleGenericError(error);
    }
  }
};

export const updateArticle = async (
  articleId: string,
  title: string,
  content: string[],
  tags: string[],
  timestamp: string
): Promise<ArticleInfo | AxiosError | Error> => {
  try {
    const response = await axios.put<ArticleInfo>(
      process.env.REACT_APP_API_URL + `/api/articles/${articleId}/update`,
      {
        title,
        content,
        tags,
        timestamp,
      }
    );

    if (response.status === 200) {
      console.log(`Article updated successfully!`);
    }
    return response.data;
  } catch (error: unknown) {
    if (axios.isAxiosError(error)) {
      return handleAxiosError(error);
    } else {
      return handleGenericError(error);
    }
  }
};

export const deleteArticle = async (
  articleId: string,
  token: string | null
): Promise<AxiosResponse | AxiosError | Error> => {
  try {
    const config = token ? { headers: { authtoken: token } } : {};
    const response = await axios.delete<AxiosResponse>(
      process.env.REACT_APP_API_URL + `/api/articles/${articleId}`,
      config
    );

    if (response.status === 200) {
      console.log(
        `Article with ID ${articleId} has been deleted successfully.`
      );
    }
    return response;
  } catch (error: unknown) {
    if (axios.isAxiosError(error)) {
      return handleAxiosError(error);
    } else {
      return handleGenericError(error);
    }
  }
};

export const upvoteArticle = async (
  articleId: string,
  token: string | null
): Promise<AxiosResponse | AxiosError | Error> => {
  const config = token ? { headers: { authtoken: token } } : {};
  try {
    const response = await axios.put<AxiosResponse>(
      process.env.REACT_APP_API_URL + `/api/articles/${articleId}/upvote`,
      null,
      config
    );
    return response;
  } catch (error: unknown) {
    if (axios.isAxiosError(error)) {
      return handleAxiosError(error);
    } else {
      return handleGenericError(error);
    }
  }
};
