import {
  ArticleContext,
  ArticleContextProps,
} from "../components/context/ArticleContext";
import {
  loadArticles,
  deleteArticle as deleteArticleApi,
  loadArticleInfo,
} from "../api/article";
import { useState, useEffect } from "react";
import { Article } from "../models/Article.model";
import useUserToken from "../hooks/useUserToken";

export const ArticleProvider: React.FC<React.PropsWithChildren<{}>> = ({
  children,
}) => {

  // need to read about useReducer and see if it can be used here
  
  const userToken: string | null = useUserToken();
  const [articles, setArticles] = useState<Article[]>([]);
  const [originalArticles, setOriginalArticles] = useState<Article[]>([]);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [tags, setTags] = useState<string[]>([]);

  // we fetch all articles, for stuff like suggestions etc

  const fetchAllArticles = async (): Promise<void> => {
    try {
      const response = await loadArticles(1);
      if ("articles" in response) setOriginalArticles(response.articles);
    } catch (error) {
      console.error("Error fetching articles:", error);
    }
  };

  // main fetching function, used for pagination

  const fetchArticles = async (
    page: number,
    limit?: number,
    query?: string,
    tag?: string[]
  ): Promise<void> => {
    const response = await loadArticles(page, limit, query, tag);
    if ("articles" in response && "pagination" in response) {
      setArticles(response.articles);
      setTotalPages(response.pagination.pageCount);
    }
  };

  const updateArticle = async (articleId: string): Promise<void> => {
    try {
      const response = await loadArticleInfo(articleId);
      const updatedArticles = originalArticles.map((article) =>
        article.name === response.name ? response : article
      );
      setOriginalArticles(updatedArticles as Article[]);
    } catch (error) {
      console.error("Error fetching article:", error);
    }
  };

  // first fetch of articles, used for pagination when list loads so its not empty

  useEffect(() => {
    fetchArticles(1, 10);
    fetchAllArticles();
  }, []);

  const deleteArticle = async (articleName: string): Promise<void> => {
    try {
      const adminToken = userToken!;
      await deleteArticleApi(articleName, adminToken);

      setArticles((prevArticles) =>
        prevArticles.filter((article) => article.name !== articleName)
      );
    } catch (error) {
      console.error("Error deleting article:", error);
    }
  };

  const searchArticle = (query: string): void => {
    setSearchQuery(query);
    fetchArticles(1, 10, query);
  };

  const filterArticles = (tags: string[]): void => {
    setTags(tags);
    fetchArticles(1, 10, searchQuery, tags);
  };

  const clearSearchResults = (): void => {
    setTags([]);
    setSearchQuery("");
    fetchArticles(1, 10);
  };

  return (
    <ArticleContext.Provider
      value={
        {
          articles,
          fetchArticles,
          fetchAllArticles,
          deleteArticle,
          searchArticle,
          clearSearchResults,
          updateArticle,
          filterArticles,
          totalPages,
          originalArticles,
          searchQuery,
          tags,
        } as ArticleContextProps
      }
    >
      {children}
    </ArticleContext.Provider>
  );
};
