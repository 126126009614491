import { useEffect, useState } from "react";
import { getAuth, onIdTokenChanged } from "firebase/auth";

// i've tried using cookies instead of localStorage bc its probably way more secure but I couldn't make
// it work without making cookies everytime the user refreshes the page, so I decided to use localStorage :(

const useAdmin = (): boolean | null => {
  const [isAdmin, setIsAdmin] = useState<boolean | null>(null);

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onIdTokenChanged(auth, async (user) => {
      try {
        if (user) {
          const idTokenResult = await user.getIdTokenResult();
          const userIsAdmin = idTokenResult.claims.role === "admin";
          setIsAdmin(userIsAdmin);
          // also we  add the authentication state in local storage or a cookie for persistence
          localStorage.setItem("adminState", JSON.stringify(userIsAdmin));
        } else {
          setIsAdmin(false);
          localStorage.setItem("adminState", JSON.stringify(false));
        }
      } catch (error) {
        console.error(error);
        setIsAdmin(false);
      }
    });
    // we cache the initial authentication state to avoid unnecessary calls to onIdTokenChanged
    const storedState = localStorage.getItem("adminState");
    if (storedState !== null) {
      setIsAdmin(JSON.parse(storedState));
    } else {
      const user = auth.currentUser;
      if (user) {
        user
          .getIdTokenResult()
          .then((idTokenResult) => {
            const userIsAdmin = idTokenResult.claims.role === "admin";
            setIsAdmin(userIsAdmin);
            localStorage.setItem(
              "adminState",
              JSON.stringify(userIsAdmin)
            );
          })
          .catch((error) => {
            console.error(error);
            setIsAdmin(false);
            localStorage.setItem("adminState", JSON.stringify(false));
          });
      }
    }
    // then we return unsubscribe to ensure that the listener is cleaned up when the component is unmounted
    return unsubscribe;
  }, []);

  return isAdmin;
};

export default useAdmin;