import { Route, Routes, Navigate } from "react-router-dom";
import { lazy } from "react";
import SuspenseWrapper from "./SuspenseWrapper";

export const PublicRoutes = () => {
  const Home = lazy(() => import("../views/Home"));
  const About = lazy(() => import("../views/About"));
  const ArticleListPage = lazy(() => import("../views/ArticleListPage"));
  const ArticlePage = lazy(() => import("../views/ArticlePage"));
  const NotFoundPage = lazy(() => import("../views/NotFoundPage"));
  const LoginPage = lazy(() => import("../views/LoginPage"));
  const CreateAccountPage = lazy(() => import("../views/CreateAccountPage"));
  const ProfilePage = lazy(() => import("../views/ProfilePage"));
  const Unauthorized = lazy(() => import("../views/Unauthorized"));
  return (
    <>
      <Routes>
        <Route path="" element={<SuspenseWrapper/>}>
        <Route path="/" element={<Home />} />
        <Route path="/home" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/articles" element={<ArticleListPage />} />
        <Route path="/articles/:articleId" element={<ArticlePage />} />
        <Route path="*" element={<NotFoundPage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/create-account" element={<CreateAccountPage />} />
        <Route path="/profile" element={<ProfilePage />} />
        <Route path="/unauthorized" element={<Unauthorized />} />
        <Route
          path="/admin"
          element={<Navigate to="/unauthorized" replace />}
        />
        </Route>
      </Routes>
    </>
  );
};
