import { Link } from "react-router-dom";

export const AdminButton = (className: string, isAdmin: boolean | null) => {
    if (isAdmin) {
      return (
        // pass className through parameters so we can have different styling depending on where its rendered
        <Link to="/admin" className={className}>
          Admin
        </Link>
      );
    }
  };