import { useNavigate } from 'react-router-dom';
import { signOut, getAuth, User } from 'firebase/auth';

export const AuthButton = (user: User | null, className: string) => {

    const navigate = useNavigate();


    if (user) {
        return (
            <button
                className={className + " !pr-[1rem]"}
                onClick={() => {
                    signOut(getAuth());
                }}
            >
                Log Out
            </button>
        );
    } else {
        return (
            <button
                className={className + ""}
                onClick={() => {
                    navigate("/login");
                }}
            >
                Log In
            </button>
        );
    }
}
