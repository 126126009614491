import { Article } from "../../models/Article.model";
import { createContext } from "react";

export interface ArticleContextProps {
  articles: Article[];
  originalArticles: Article[];
  deleteArticle: (articleName: string) => Promise<void>;
  searchArticle: (query: string) => void;
  clearSearchResults: () => void;
  fetchArticles: (pages: number, limit?:number, query?:string, tag?:string[]) => void;
  fetchAllArticles: () => void;
  filterArticles: (tags: string[]) => void;
  updateArticle: (articleName: string) => Promise<void>;
  totalPages: number;
  searchQuery?: string;
  tags?: string[];
}

export const initialArticleContext: Partial<ArticleContextProps> = {
  articles: [],
  originalArticles: [],
  deleteArticle: async (articleName: string) => {},
  searchArticle: (query: string) => {},
  clearSearchResults: () => {},
  fetchArticles: (pages, limit?, query?, tag?) => {},
  fetchAllArticles: () => {},
  updateArticle: async (articleName: string) => {},
  filterArticles: (tags: string[]) => {},
  totalPages: 1,
  searchQuery: "",
  tags: [],
};

export const ArticleContext = createContext<ArticleContextProps>(initialArticleContext as ArticleContextProps);