import { AppRouter } from "./routes/AppRouter";
import { BrowserRouter } from "react-router-dom";
import ThemeProvider from "./hooks/useTheme";
import { ArticleProvider } from "./provider/ArticleProvider";
import Navbar from "./components/Navbar";
import "./App.css";

function App() {
  return (
    <>
      <ThemeProvider>
        <ArticleProvider>
          <BrowserRouter>
            <Navbar />
            <div className="w-100 max-w-[640px] container m-auto p-6 pt-0 flex flex-col gap-5">
              <AppRouter />
            </div>
          </BrowserRouter>
        </ArticleProvider>
      </ThemeProvider>
    </>
  );
}

export default App;
