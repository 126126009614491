import { useEffect, useState } from 'react';
import { getAuth, onIdTokenChanged } from 'firebase/auth';

const useUserToken = (): string | null => {
    
  const [userToken, setUserToken] = useState<string | null>(null);

  useEffect(() => {
    const auth = getAuth();

    // set up an auth state change listener to get the user token when the user is signed in or signs out
    const unsubscribe = onIdTokenChanged(auth, async (user) => {
      if (user) {
        // if the user is signed in, get the user token
        const userTokenResult = await user.getIdToken();
        setUserToken(userTokenResult);
      } else {
        // when he signs out, we set it to null
        setUserToken(null);
      }
    });
    // cleanup
    return () => unsubscribe();
  }, []);

  return userToken;
};

export default useUserToken;
