import { Route, Routes, Navigate } from "react-router-dom";
import { PrivateRoutes } from "./PrivateRoutes";
import { PublicRoutes } from "./PublicRoutes";
import useAdmin from "../hooks/useAdmin";
import { useState, useEffect } from "react";

type Status = "checking" | "authenticated" | "unauthenticated";

export const AppRouter = () => {
  const isAdmin = useAdmin();
  const [status, setStatus] = useState<Status>("checking");

  useEffect(() => {
    if (isAdmin === true) {
      setStatus("authenticated");
    } else if (isAdmin === false) {
      setStatus("unauthenticated");
    }
  }, [isAdmin]);
  
  if (status === "checking") {
    return <div className="loading">Checking credentials...</div>;
  }

  return (
    <Routes >
      {status === "authenticated" ? (
        <Route path="/*" element={<PrivateRoutes />} />
      ) : (
        <Route path="/*" element={<PublicRoutes />} />
      )}

      <Route path="*" element={<Navigate to="/login" replace />} />
    </Routes>
  );
};

