import { useEffect, useState, createContext } from "react";
import { themeChange } from "theme-change";

export const ThemeContext = createContext({
  selectedTheme: "",
  handleThemeChange: (theme: string) => {},
});

const ThemeProvider = ({ children }: { children: React.ReactNode }) => {
  const [selectedTheme, setSelectedTheme] = useState("");

   // this makes the theme be persistent around the page

  useEffect(() => {
    // retrieve the selected theme from local storage
    const storedTheme = localStorage.getItem("selectedTheme");
    if (storedTheme) {
      setSelectedTheme(storedTheme);
      themeChange(storedTheme === "dracula");
    } else {
      setSelectedTheme("dracula"); // if there is no theme selected, add a default theme
      themeChange(false);
    }
  }, []);

  // change the theme to selectedTheme to avoid bug
  useEffect(() => {
    localStorage.setItem("theme", selectedTheme);
    themeChange(selectedTheme === "null");
  }, [selectedTheme]);

  const handleThemeChange = (theme: string) => {
    setSelectedTheme(theme);
    themeChange(theme === "null");

    // and then update the local storage immediately after changing the selected theme
    localStorage.setItem("selectedTheme", theme);
  };

  const themeContextValue: {
    selectedTheme: string;
    handleThemeChange: (theme: string) => void;
  } = {
    selectedTheme,
    handleThemeChange,
  };

  return (
    <ThemeContext.Provider value={themeContextValue}>
      {children}
    </ThemeContext.Provider>
  );
};

export default ThemeProvider;
