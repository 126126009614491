import { Outlet } from "react-router-dom";
import { Suspense } from "react";

export default function SuspenseWrapper () {
    return (
      <>
        <Suspense fallback={<div className="loading"></div>}>
          <Outlet />
        </Suspense>
      </>
    )
  }